<template>
  <!-- Dialog Add Competitor -->
  <div id="dialog-story-mode-market-potential">
    <ib-dialog
      :visible="dialogMarketPotentialVisible"
      :fullscreen="true"
      no-scroll-to-top-after-close
      @open="openDialog"
      @close="closeDialog"
      @closed="closedDialog"
    >
      <!-- Mobile title -->
      <template #title>
        <ib-dialog-container class="d-md-none">
          <h2 v-if="marketTemplate === 'total'" class="m-0">
            {{ $t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.description') }}
          </h2>

          <h2 v-if="marketTemplate === 'available'" class="m-0">
            {{ $t('pages.businessGuide.marketPotential.steps.serviceableAvailableMarket.description') }}
          </h2>

          <h2 v-if="marketTemplate === 'share'" class="m-0">
            {{ $t('pages.businessGuide.marketPotential.steps.shareOfMarket.description') }}
          </h2>
        </ib-dialog-container>
      </template>
      <!-- /Mobile title -->

      <!-- Content Wrapper -->
      <ib-dialog-container class="content-wrapper">
        <!-- Total Market -->
        <div v-show="marketTemplate === 'total'">
          <!-- Header -->
          <add-dialog-header>
            <template #left>
              <!-- Title -->
              <h2 class="m-0">
                {{ $t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.description') }}
              </h2>
              <!-- /Title -->
            </template>
          </add-dialog-header>
          <!-- /Header -->

          <!-- Guide -->
          <info-guide-add-dialog
            class="mb-5"
            :visible="guideVisible"
            :title="$t('pages.businessGuide.marketPotential.addDialog.guide.totalAddressable.title')"
            :text="$t('pages.businessGuide.marketPotential.addDialog.guide.totalAddressable.text')"
            @click="onToggleDialogGuide"
          />
          <!-- /Guide -->

          <!-- Inner Wrapper -->
          <div class="inner-wrapper">
            <div class="grid-wrapper">
              <el-row :gutter="60">
                <!-- Form Holder -->
                <el-col>
                  <el-form>
                    <el-row :gutter="22">
                      <!-- Coverage -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label"
                          :label="$t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.fields.coverage')"
                          :class="{'focused': focus.coverage}"
                        >
                          <el-input
                            v-model="calcMarket.coverage"
                            placeholder=""
                            disabled="disabled"
                            @focus="toggleFocus('coverage')"
                            @blur="toggleFocus('coverage')"
                          />
                        </el-form-item>
                      </el-col>
                      <!-- /Coverage -->

                      <!-- Total Population -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label wider"
                          :label="$t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.fields.totalPopulation')"
                          :class="{'focused': focus.total_population}"
                        >
                          <div class="el-input">
                            <currency-input
                              v-model="form.total_population"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :allow-negative="false"
                              :value-range="{min: 0, max: 10000000000}"
                              :precision="0"
                              locale="en-US"
                              @focus="toggleFocus('total_population')"
                              @blur="toggleFocus('total_population')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Total Population -->

                      <!-- Potential Customers Percentage -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.fields.potentialCustomersPercent')"
                          :class="{'focused': focus.potential_customers}"
                        >
                          <div class="el-input">
                            <currency-input
                              v-model="form.potential_customers"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0, max: 100}"
                              :precision="{min: 0, max: 2}"
                              :currency="{suffix: '%'}"
                              :allow-negative="false"
                              locale="en-US"
                              @focus="toggleFocus('potential_customers')"
                              @blur="toggleFocus('potential_customers')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Potential Customers Percentage -->

                      <!-- Potential Customers Number -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.fields.potentialCustomers')"
                          :class="{'focused': focus.potential_customers_number}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="calcMarket.potential_customers_number"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="null"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('potential_customers_number')"
                              @blur="toggleFocus('potential_customers_number')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Potential Customers Number -->

                      <!-- Annual Spending -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="`${$t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.fields.annualSpending')} ${currencySymbol}`"
                          :class="{'focused': focus.annual_spending}"
                        >
                          <div class="el-input">
                            <currency-input
                              v-model="form.annual_spending"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="currencyCode"
                              :allow-negative="false"
                              locale="en-US"
                              @focus="toggleFocus('annual_spending')"
                              @blur="toggleFocus('annual_spending')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Annual Spending -->

                      <!-- Total Revenue -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.marketPotential.steps.totalAddressableMarket.fields.totalRevenue')"
                          :class="{'focused': focus.total_revenue}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="calcMarket.total_revenue"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="currencyCode"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('total_revenue')"
                              @blur="toggleFocus('total_revenue')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Total Revenue -->
                    </el-row>
                  </el-form>
                  <!-- Close And Save -->
                  <div class="dialog-bottom">

                    <!-- Divider -->
                    <ib-divider block class="my-4" />
                    <!-- /Divider -->

                    <el-row :gutter="10" class="d-flex justify-content-sm-end mt-4 mb-4">
                      <el-col :span="12">
                        <!-- Close -->
                        <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="discardTotalRevenue">
                          {{ $t('discard') }}
                        </ib-button>
                        <!-- /Close -->
                      </el-col>

                      <el-col :span="12">
                        <ib-button
                          class="w-100 text-uppercase"
                          size="lg"
                          :loading="loading"
                          :disabled="!calcMarket.total_revenue"
                          @click="saveTotalRevenue"
                        >
                          {{ $t('save') }}
                        </ib-button>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- /Close And Save -->
                </el-col>
                <!-- /Form Holder -->
              </el-row>
            </div>
          </div>
          <!-- /Inner Wrapper -->
        </div>
        <!-- /Total Market -->

        <!-- Available Market -->
        <div v-show="marketTemplate === 'available'">
          <!-- Header -->
          <add-dialog-header>
            <template #left>
              <!-- Title -->
              <h2 class="m-0">
                {{ $t('pages.businessGuide.marketPotential.steps.serviceableAvailableMarket.description') }}
              </h2>
              <!-- /Title -->
            </template>
          </add-dialog-header>
          <!-- /Header -->

          <!-- Guide -->
          <info-guide-add-dialog
            class="mb-5"
            :visible="guideVisible"
            :title="$t('pages.businessGuide.marketPotential.addDialog.guide.serviceableAvailable.title')"
            :text="$t('pages.businessGuide.marketPotential.addDialog.guide.serviceableAvailable.text')"
            @click="onToggleDialogGuide"
          />
          <!-- /Guide -->

          <!-- Inner Wrapper -->
          <div class="inner-wrapper">
            <div class="grid-wrapper">
              <el-row :gutter="60">
                <!-- Form Holder -->
                <el-col>
                  <el-form>
                    <el-row :gutter="22">
                      <!-- Potential Customers -->
                      <el-col>
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.marketPotential.steps.serviceableAvailableMarket.fields.potentialCustomers')"
                          :class="{'focused': focus.potential_customers_number}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="calcMarket.potential_customers_number"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="null"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('potential_customers_number')"
                              @blur="toggleFocus('potential_customers_number')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Potential Customers -->

                      <!-- Target Customers Percentage -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label wider"
                          :label="$t('pages.businessGuide.marketPotential.steps.serviceableAvailableMarket.fields.targetCustomersPercent')"
                          :class="{'focused': focus.target_customers}"
                        >
                          <div class="el-input">
                            <currency-input
                              v-model="form.target_customers"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0, max: 100}"
                              :precision="{min: 0, max: 2}"
                              :currency="{suffix: '%'}"
                              :allow-negative="false"
                              locale="en-US"
                              @focus="toggleFocus('target_customers')"
                              @blur="toggleFocus('target_customers')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Target Customers Percentage -->

                      <!-- Target Customers Number -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.marketPotential.steps.serviceableAvailableMarket.fields.targetCustomers')"
                          :class="{'focused': focus.target_customers_number}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="calcMarket.target_customers_number"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="null"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('target_customers_number')"
                              @blur="toggleFocus('target_customers_number')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Target Customers Number -->

                      <!-- Annual Spending -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="`${$t('pages.businessGuide.marketPotential.steps.serviceableAvailableMarket.fields.annualSpending')} ${currencySymbol}`"
                          :class="{'focused': focus.annual_spending}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="form.annual_spending"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="currencyCode"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('annual_spending')"
                              @blur="toggleFocus('annual_spending')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Annual Spending -->

                      <!-- Total Revenue -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.marketPotential.steps.serviceableAvailableMarket.fields.totalRevenue')"
                          :class="{'focused': focus.available_total_revenue}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="calcMarket.available_total_revenue"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="currencyCode"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('available_total_revenue')"
                              @blur="toggleFocus('available_total_revenue')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Total Revenue -->
                    </el-row>
                  </el-form>
                  <!-- Close And Save -->
                  <div class="dialog-bottom">

                    <!-- Divider -->
                    <ib-divider block class="my-4" />
                    <!-- /Divider -->

                    <el-row :gutter="10" class="d-flex justify-content-sm-end mt-4 mb-4">
                      <el-col :span="12">
                        <!-- Close -->
                        <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="discardTotalRevenue">
                          {{ $t('discard') }}
                        </ib-button>
                        <!-- /Close -->
                      </el-col>

                      <el-col :span="12">
                        <ib-button
                          class="w-100 text-uppercase"
                          size="lg"
                          :loading="loading"
                          :disabled="!calcMarket.available_total_revenue"
                          @click="saveTotalRevenue"
                        >
                          {{ $t('save') }}
                        </ib-button>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- /Close And Save -->
                </el-col>
                <!-- /Form Holder -->
              </el-row>
            </div>
          </div>
          <!-- /Inner Wrapper -->
        </div>
        <!-- /Available Market -->

        <!-- Share of Market -->
        <div v-show="marketTemplate === 'share'">

          <!-- Header -->
          <add-dialog-header>
            <template #left>
              <!-- Title -->
              <h2 class="m-0">
                {{ $t('pages.businessGuide.marketPotential.steps.shareOfMarket.description') }}
              </h2>
              <!-- /Title -->
            </template>
          </add-dialog-header>
          <!-- /Header -->

          <!-- Guide -->
          <info-guide-add-dialog
            class="mb-5"
            :visible="guideVisible"
            :title="$t('pages.businessGuide.marketPotential.addDialog.guide.shareOfMarket.title')"
            :text="$t('pages.businessGuide.marketPotential.addDialog.guide.shareOfMarket.text')"
            @click="onToggleDialogGuide"
          />
          <!-- /Guide -->

          <!-- Inner Wrapper -->
          <div class="inner-wrapper">
            <div class="grid-wrapper">
              <el-row :gutter="60">
                <!-- Form Holder -->
                <el-col>
                  <el-form>
                    <el-row :gutter="22">
                      <!-- Target Customers -->
                      <el-col>
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.marketPotential.steps.shareOfMarket.fields.targetCustomers')"
                          :class="{'focused': focus.target_customers_number}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="calcMarket.target_customers_number"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="null"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('target_customers_number')"
                              @blur="toggleFocus('target_customers_number')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Target Customers -->

                      <!-- Your Market Share Percentage -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label wider"
                          :label="$t('pages.businessGuide.marketPotential.steps.shareOfMarket.fields.yourMarketSharePercent')"
                          :class="{'focused': focus.market_share}"
                        >
                          <div class="el-input">
                            <currency-input
                              v-model="form.market_share"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0, max: 100}"
                              :precision="{min: 0, max: 2}"
                              :currency="{suffix: '%'}"
                              :allow-negative="false"
                              locale="en-US"
                              @focus="toggleFocus('market_share')"
                              @blur="toggleFocus('market_share')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Your Market Share Percentage -->

                      <!-- Obtainable Customers Number -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.marketPotential.steps.shareOfMarket.fields.obtainableCustomers')"
                          :class="{'focused': focus.obtainable_customers_number}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="calcMarket.obtainable_customers_number"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="null"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('obtainable_customers_number')"
                              @blur="toggleFocus('obtainable_customers_number')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Obtainable Customers Number -->

                      <!-- Annual Spending -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="`${$t('pages.businessGuide.marketPotential.steps.shareOfMarket.fields.annualSpending')} ${currencySymbol}`"
                          :class="{'focused': focus.annual_spending}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="form.annual_spending"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="currencyCode"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('annual_spending')"
                              @blur="toggleFocus('annual_spending')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Annual Spending -->

                      <!-- Total Revenue -->
                      <el-col :md="12">
                        <el-form-item
                          class="input-inside-label" :label="$t('pages.businessGuide.marketPotential.steps.shareOfMarket.fields.totalRevenue')"
                          :class="{'focused': focus.share_total_revenue}"
                        >
                          <div class="el-input is-disabled">
                            <currency-input
                              v-model="calcMarket.share_total_revenue"
                              class="el-input__inner"
                              :distraction-free="false"
                              :value-range="{min: 0}"
                              :precision="{min: 0, max: 2}"
                              :currency="currencyCode"
                              :allow-negative="false"
                              disabled="disabled"
                              locale="en-US"
                              @focus="toggleFocus('share_total_revenue')"
                              @blur="toggleFocus('share_total_revenue')"
                            />
                          </div>
                        </el-form-item>
                      </el-col>
                      <!-- /Total Revenue -->
                    </el-row>
                  </el-form>
                  <!-- Close And Save -->
                  <div class="dialog-bottom">

                    <!-- Divider -->
                    <ib-divider block class="my-4" />
                    <!-- /Divider -->

                    <el-row :gutter="10" class="d-flex justify-content-sm-end mt-4 mb-4">
                      <el-col :span="12">
                        <!-- Close -->
                        <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="discardTotalRevenue">
                          {{ $t('discard') }}
                        </ib-button>
                        <!-- /Close -->
                      </el-col>

                      <el-col :span="12">
                        <ib-button
                          class="w-100 text-uppercase"
                          size="lg"
                          :loading="loading"
                          :disabled="!calcMarket.share_total_revenue"
                          @click="saveTotalRevenue"
                        >
                          {{ $t('save') }}
                        </ib-button>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- /Close And Save -->
                </el-col>
                <!-- /Form Holder -->
              </el-row>
            </div>
          </div>
          <!-- /Inner Wrapper -->
        </div>
        <!-- /Share of Market -->

      </ib-dialog-container>
      <!-- /Content Wrapper -->

    </ib-dialog>
  </div>
  <!-- /Dialog Add Competitor -->

</template>

<script>
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader.vue'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCurrencies from '@/mixins/currencies'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'

export default {
  name: 'DialogMarketPotential',

  components: {
    AddDialogHeader,
    InfoGuideAddDialog
  },

  mixins: [
    MixinDialog,
    MixinGuide,
    MixinCurrencies
  ],

  props: {
    dialogMarketPotentialVisible: {
      type: Boolean,
      default: false
    },
    marketTemplate: {
      type: String,
      default: ''
    },
    marketPotentialData: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      loading: false,
      guideVisible: false,
      ideaId: this.$store.state.idea.id,
      form: {
        id: null,
        total_population: null,
        potential_customers: null,
        annual_spending: null,
        target_customers: null,
        market_share: null
      },
      calcMarket: {
        coverage: this.$store.state.idea.storyMode.research.market.coverageElement ? this.$store.state.idea.storyMode.research.market.coverageElement.title : 'Not defined',
        potential_customers_number: null,
        total_revenue: null,
        target_customers_number: null,
        available_total_revenue: null,
        obtainable_customers_number: null,
        share_total_revenue: null
      },
      focus: {
        coverage: false,
        total_population: false,
        potential_customers: false,
        potential_customers_number: false,
        annual_spending: false,
        total_revenue: false,
        target_customers: false,
        target_customers_number: false,
        available_total_revenue: false,
        obtainable_customers_number: false,
        share_total_revenue: false,
        market_share: false
      }
    }
  },

  computed: {
    isDialogOpen () {
      return this.dialogAddCompetitorVisible
    }
  },

  watch: {
    'form.total_population' (prop) {
      if (prop < 0) {
        prop = null
      }

      this.form.total_population = prop
      this.calculationTotalMarket()
    },

    'form.potential_customers' (prop) {
      if (prop >= 100) {
        prop = 100
      } else if (prop < 0) {
        prop = null
      }

      this.form.potential_customers = prop
      this.calculationTotalMarket()
    },

    'form.annual_spending' (prop) {
      if (prop < 0) {
        prop = null
      }

      this.form.annual_spending = prop
      this.calculationTotalMarket()
    },

    'form.target_customers' (prop) {
      if (prop >= 100) {
        prop = 100
      } else if (prop < 0) {
        prop = null
      }

      this.form.target_customers = prop
      this.calculationTotalMarket()
    },

    'form.market_share' (prop) {
      if (prop >= 100) {
        prop = 100
      } else if (prop < 0) {
        prop = null
      }

      this.form.market_share = prop
      this.calculationTotalMarket()
    }
  },

  methods: {
    calculationTotalMarket () {
      if (this.form.total_population && this.form.potential_customers) {
        this.calcMarket.potential_customers_number = this.form.total_population * (this.form.potential_customers / 100)
        this.focus.potential_customers_number = true
      } else {
        this.calcMarket.potential_customers_number = null
        this.focus.potential_customers_number = false
      }

      if (this.calcMarket.potential_customers_number && this.form.annual_spending) {
        this.calcMarket.total_revenue = this.calcMarket.potential_customers_number * this.form.annual_spending
        this.focus.total_revenue = true
      } else {
        this.calcMarket.total_revenue = null
        this.focus.total_revenue = false
      }

      if (this.form.target_customers) {
        this.calcMarket.target_customers_number = this.calcMarket.potential_customers_number * (this.form.target_customers / 100)
        this.calcMarket.available_total_revenue = this.calcMarket.target_customers_number * this.form.annual_spending
        this.focus.target_customers_number = true
        this.focus.available_total_revenue = true
      } else {
        this.calcMarket.target_customers_number = null
        this.calcMarket.available_total_revenue = null
        this.focus.target_customers_number = false
        this.focus.available_total_revenue = false
      }

      if (this.form.market_share) {
        this.calcMarket.obtainable_customers_number = this.calcMarket.target_customers_number * (this.form.market_share / 100)
        this.calcMarket.share_total_revenue = this.calcMarket.obtainable_customers_number * this.form.annual_spending
        this.focus.obtainable_customers_number = true
        this.focus.share_total_revenue = true
      } else {
        this.calcMarket.obtainable_customers_number = null
        this.calcMarket.share_total_revenue = null
        this.focus.obtainable_customers_number = false
        this.focus.share_total_revenue = false
      }
    },

    closeDialog () {
      this.$emit('close-dialog-market-potential')
    },

    closedDialog () {
      this.guideVisible = false
    },

    openDialog () {
      if (this.marketTemplate === 'total') {
        this.openGuideDialog('addMarketPotentialGuide')
      }

      if (this.marketTemplate === 'available') {
        this.openGuideDialog('addMarketPotentialAvailableGuide')
      }

      if (this.marketTemplate === 'share') {
        this.openGuideDialog('addMarketPotentialShareGuide')
      }

      this.form = Object.assign({}, this.$store.state.idea.storyMode.research.marketPotential)

      const focusObj = Object.keys(this.focus)
      focusObj.forEach(prop => {
        this.checkInputLength(prop)
      })

      this.calculationTotalMarket()
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (Object.prototype.hasOwnProperty.call(this.form, input)) {
        if (this.form[input] !== null && this.form[input] !== '') this.focus[input] = true
      }

      if (Object.prototype.hasOwnProperty.call(this.calcMarket, input)) {
        if (this.calcMarket[input] !== null && this.calcMarket[input] !== '') this.focus[input] = true
      }
    },

    checkInputLength (input) {
      if (Object.prototype.hasOwnProperty.call(this.form, input)) {
        this.focus[input] = this.form[input] !== null && this.form[input] !== ''
      }

      if (Object.prototype.hasOwnProperty.call(this.calcMarket, input)) {
        this.focus[input] = this.calcMarket[input] !== null && this.calcMarket[input] !== ''
      }
    },

    saveTotalRevenue () {
      this.loading = true
      this.$http.put(`story-mode/research/market-potential/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.form = Object.assign({}, response.data.payload.marketPotential)
            this.$store.commit('idea/setMarketPotential', response.data.payload.marketPotential)
            this.$emit('on-save-data', this.form)
          }
        }).finally(() => {
          this.loading = false
        })
    },

    discardTotalRevenue () {
      if (this.marketTemplate === 'total') {
        this.form.total_population = null
        this.form.potential_customers = null
        this.form.annual_spending = null
        this.form.target_customers = null
        this.form.market_share = null
      }

      if (this.marketTemplate === 'available') {
        this.form.target_customers = null
        this.form.market_share = null
      }

      if (this.marketTemplate === 'share') {
        this.form.market_share = null
      }

      this.saveTotalRevenue()
    }
  }
}
</script>
